<template>
	<div>
		<div class="mb20">
			<span class="ft20 cl-main ftw600">请重新授权</span>
		</div>
		<a-alert message="您的小程序已经取消了授权,重新授权才能继续使用" type="warning" show-icon />
		
		<div class="miniapp-form-box mt30">
			<div class="text-center">
					<img class="miniapp-logo" :src="value.wx_face" />
			</div>
			<div class="text-center mt16 ft18 ftw600 cl-main">
				{{value.wx_name}}
			</div>
			<div class="text-center mt16 ft14 cl-info">
				{{value.wx_principal_name}}
			</div>
			<div class="flex center mt24">
				<div class="version-box">
					<span class="ft14 cl-main ftw600">当前版本：</span>
					<span class="ft14 ftw600" style="color:#5333FF;">{{value.v ? 'v' + value.v : 'v1.0'}}</span>
				</div>
			</div>
			
			<div class="mt30 flex center">
				<miniapp-authurl title="重新授权"></miniapp-authurl>
			</div>
		</div>		
	</div>
</template>

<script>
	import miniappAuthurl from './authurl.vue';
	export default{
		components:{
			miniappAuthurl
		},
		props:{
			value:{
				type:Object,
				default:function(){
					return new Object;
				}
			}
		},
		data(){
			return {
				
			}
		},
		created(){
			
		}
	}
</script>

<style>
	.miniapp-form-box{
		width: 1000px;
		min-height: 422px;
		background: #FAFDFF;
		border-radius: 4px;
		border: 1px solid #F0F3F5;
		padding: 60px;
	}
	.miniapp-logo{
		width: 80px;
		height: 80px;
		border-radius: 40px;
	}
	.version-box{
		width: 160px;
		height: 50px;
		border-top: 1px solid #E8EBED;
		border-bottom: 1px solid #E8EBED;
		display: flex;
		justify-content: center;
		align-items: center;
	}
</style>