<template>
  <div>
    <a-button type="danger" @click="uploadAct" :loading="loading">{{title}}</a-button>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "上传代码",
    },
  },
  data() {
    return {
      loading: false,
      //  rules: {
      //   trading_id: [{ required: true, message: '请选择所属模型', trigger: 'blur' },],
      //   stock_code: [{ required: true, message: '请输入代码', trigger: 'blur' },],
      //   average_price: [{ required: true, message: '请输入平均价', trigger: 'blur' },],
      //   loss: [{ required: true, message: '请输入盈亏', trigger: 'blur' },],

      //   add_time: [{ required: true, message: '请输入时间', trigger: 'blur' },],
      //   // stock_code: [{ required: true, message: '请输入代码', trigger: 'blur' },],

      // },
    };
  },
  methods: {
    uploadAct() {
      this.loading = true;
      this.$http
        .papi("platform/miniapp/uploadCode")
        .then((res) => {
          this.loading = false;
          this.$message.success("上传代码成功");
          this.refreshPage("/miniapp");
        })
        .catch((res) => {
          this.loading = false;
        });
    },
  },
};
</script>

<style>
</style>