<template>
	<div>
		<a-button @click="getAuthUrl" type="primary">
		      {{title}}
		</a-button>
	</div>
</template>

<script>
	export default{
		props:{
			title:{
				type:String,
				default:'立即授权微信小程序'
			},
		},
		data(){
			return {
				
			}
		},
		methods:{
			getAuthUrl(){
				let CallBackUrl =  process.env.VUE_APP_OPEN_AUTH_URL + 'miniapp/callback';
			
				this.loading = true;
				this.$http.papi('platform/miniapp/getAuth',{callback:CallBackUrl}).then(res=>{
					this.loading = false;
					location.href = res.url;
				}).catch(res=>{
					this.loading = false;
				});
			}
		}
	}
</script>

<style>
	
</style>