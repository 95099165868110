<template>
	<div>
		<a-spin :spinning="loading">
		<div class="mb20">
			<span class="ft20 cl-main ftw600 ">授权小程序</span>
		</div>
		<a-alert message="目前只支持微信小程序的绑定,绑定微信小程序后您将解锁微信会员制营销" type="warning" show-icon />
				
		<div class="flex center mt30">
			<div class="auth-box">
				<div class="text-center" style="line-height: 1;">
					<i class="iconfont iconicon_miniprogram cl-notice" style="font-size: 60px;"></i>
				</div>
				<div class="text-center ft16 ftw600 cl-notice mt24">还没有微信小程序</div>
				<div class="mt15 ft12 cl-notice">
					已经有公众号，从公众号后台中创建小程序可免去微信认证流程，没有则点击下方注册按钮创建企业主体小程序即可。
				</div>
				<div class="flex center mt30">
					<a href="https://mp.weixin.qq.com/" target="_blank">
						<a-button type="primary" ghost>
							  前往注册小程序
						</a-button>
					</a>
				</div>
			</div>
			<div class="auth-box ml40">
				<div class="text-center" style="line-height: 1;">
					<i class="iconfont iconicon_miniprogram cl-green" style="font-size: 60px;"></i>
				</div>
				<div class="text-center ft16 ftw600 cl-green mt24">已经拥有微信小程序</div>
				<div class="mt15 ft12 cl-notice">
					为配合国家网监部对微信小程序的管理，建议先完成店铺认证，请确保小程序认证主体和当前店铺主体一致。
				</div>
				<div class="flex center mt30">
					<miniapp-authurl></miniapp-authurl>
				</div>
			</div>
		</div>
		</a-spin>
				
	</div>
</template>

<script>
	import miniappAuthurl from './authurl.vue';
	export default{
		components:{
			miniappAuthurl
		},
		data(){
			return {
				loading:false
			}
		},
		created(){
			
		},
		methods:{
			
		}
	}
</script>

<style>
	.auth-box{
		width: 480px;
		height: 342px;
		background: #FFFFFF;
		border-radius: 16px;
		border: 2px dashed #E4E6ED;
		padding: 50px 40px;
	}
</style>