<template>
  <div>
    <a-spin :spinning="loading">
      <div class="miniapp-main">
        <div class="miniapp-box">
          <!-- //0 等待授权  1 重新授权  2等待上传代码  3提交审核    4等待审核 5 审核失败  6审核成功  8上线中 -->
          <auth-step v-model="auth" v-if="getStep == 0"></auth-step>
          <reauth-step v-model="auth" v-if="getStep == 1"></reauth-step>
          <!--  -->
          <upload-step v-model="auth" v-if="getStep == 2"></upload-step>
          <submit-step v-model="auth" v-if="getStep == 3"></submit-step>
          <wait-step v-model="auth" v-if="getStep == 4"></wait-step>
          <fail-step v-model="auth" v-if="getStep == 5"></fail-step>
          <success-step v-model="auth" v-if="getStep == 6"></success-step>
          <online-step v-model="auth" v-if="getStep == 8"></online-step>
        </div>
      </div>
    </a-spin>
  </div>
</template>

<script>
import authStep from "./auth.vue";
import reauthStep from "./reauth.vue";
import uploadStep from "./upload.vue";
import submitStep from "./submit.vue";
import waitStep from "./wait.vue";
import failStep from "./fail.vue";
import onlineStep from "./online.vue";
import successStep from "./success.vue";
export default {
  components: {
    authStep,
    submitStep,
    uploadStep,
    reauthStep,
    waitStep,
    failStep,
    successStep,
    onlineStep,
  },

  data() {
    return {
      loading: false,
      auth: null,
    };
  },
  computed: {
    getStep() {
      //0 等待授权  1 重新授权  2等待上传代码  3提交审核    4等待审核 5 审核失败  6审核成功  8上线中
      let auth = this.auth;
      if (auth == null || auth.status == 0) {
        return 0;
      }
      if (auth.status == -1) {
        return 1;
      }
      if (auth.code_status == 0) {
        return 2;
      }
      if (auth.code_status == 1) {
        return 3;
      }
      if (auth.code_status == 2) {
        return 4;
      }
      if (auth.code_status == 4) {
        return 5;
      }
      if (auth.code_status == 5) {
        return 6;
      }
      return 8;
    },
  },
  created() {
    this.getDatas();
  },
  methods: {
    uploadYes() {
      this.getDatas();
    },
    onlineYes() {
      this.getDatas();
    },
    getDatas() {
      this.loading = true;
      this.$http
        .papi("platform/miniapp/getShopInfo")
        .then((res) => {
          this.loading = false;
          this.auth = res.auth;
          console.log(res.auth, "authauthauthauth");
        })
        .catch((res) => {
          this.loading = false;
        });
    },
  },
};
</script>

<style>
.miniapp-main {
  padding: 60px;
  display: flex;
  justify-content: center;
  background: #ffffff;
  min-height: 800px;
}
.miniapp-box {
  width: 1000px;
}
</style>