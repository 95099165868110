<template>
  <div>
    <a-spin :spinning="loading">
      <div class="mb20">
        <span class="ft20 cl-main ftw600">{{value.is_upgrade == 1 ? '升级代码' : '上传代码'}}</span>
      </div>
      <a-alert message="上传小程序代码" type="warning" show-icon />
      <div class="miniapp-form-box mt30">
        <div class="text-center">
          <img class="miniapp-logo" :src="value.wx_face" />
        </div>
        <div class="text-center mt16 ft18 ftw600 cl-main">
          {{value.wx_name}}
        </div>
        <div class="text-center mt16 ft14 cl-info">
          {{value.wx_principal_name}}
        </div>
        <div class="flex center mt24">
          <div class="version-box">
            <span class="ft14 cl-main ftw600">当前版本：</span>
            <span class="ft14 ftw600" style="color:#5333FF;">{{value.v ? 'v' + value.v : 'v1.0'}}</span>
          </div>
        </div>
        <!-- 表单提交 小程序信息 -->
        <div class="form-xcx">
          <a-form :model="formState" name="basic" :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }" autocomplete="off">
            <a-form-item label="模版ID" name="templateID" :rules="[{ required: true, message: '请输入模版ID!' }]">
              <a-input v-model="formState.templateID" placeholder="请输入模版ID" />
            </a-form-item>
            <a-form-item label="版本号" name="templateVS" :rules="[{ required: true, message: '请输入版本号!' }]">
              <a-input v-model="formState.templateVS" placeholder="请输入版本号" />
            </a-form-item>
            <a-form-item label="版本描述" name="templateDe" :rules="[{ required: true, message: '请输入版本描述' }]">
              <a-textarea v-model="formState.templateDe" placeholder="请输入版本描述" :rows="4" />
            </a-form-item>
          </a-form>
        </div>
        <!-- flex center -->
        <div class="mt30 flex center">
          <!-- 上传代码 -->
          <!-- <a-button type="danger" @click="uploadAct" :loading="loading">上传代码</a-button> -->
          <miniappUploadAct></miniappUploadAct>
        </div>
      </div>

    </a-spin>

  </div>
</template>

<script>
import miniappUploadAct from "./uploadAct.vue";
export default {
  components: {
    miniappUploadAct,
  },
  data() {
    return {
      loading: false,
      uploadInfo: {},
      formState: {
        templateID: "",
        templateVS: "",
        templateDe: "",
      },
    };
  },
  props: {
    value: {
      type: Object,
      default: function () {
        return new Object();
      },
    },
  },

  created() {},
  methods: {
    uploadAct() {
      this.loading = true;
      this.$http
        .papi("/auto/admin/sendcode", this.formState)
        .then((res) => {
          this.loading = false;
          this.$message.success("上传代码成功");
          this.refreshPage("/miniapp");
        })
        .catch((res) => {
          this.loading = false;
        });
    },
    geterify_ticket() {
      let data = {
        shop_id: "3",
      };
      this.loading = true;
      this.$http
        .papi("/auto/admin/getconfig", data)
        .then((res) => {
          this.loading = false;
          this.uploadInfo = res;

          console.log(res, "authauthauthauth");
        })
        .catch((res) => {
          this.loading = false;
        });
    },
  },
};
</script>

<style>
.miniapp-form-box {
  width: 1000px;
  min-height: 422px;
  background: #fafdff;
  border-radius: 4px;
  border: 1px solid #f0f3f5;
  padding: 60px;
}
.miniapp-logo {
  width: 80px;
  height: 80px;
  border-radius: 40px;
}
.version-box {
  width: 160px;
  height: 50px;
  border-top: 1px solid #e8ebed;
  border-bottom: 1px solid #e8ebed;
  display: flex;
  justify-content: center;
  align-items: center;
}
.form-xcx {
  margin: 20px auto;
  width: 600px !important;
}
</style>