<template>
	<div>
		<a-button type="primary" @click="backAct">{{title}}</a-button>
	</div>
</template>

<script>
	export default{
		props:{
			title:{
				type:String,
				default:'撤回审核',
			}
		},
		data(){
			return {
				
			}
		},
		methods:{
			backAct(){
				this.loading = true;
				this.$http.papi('platform/miniapp/back').then(res=>{
					this.loading = false;
					this.$message.success('撤回审核成功');
					this.refreshPage('/miniapp');
				}).catch(res=>{
					this.loading = false;
				});
			}
		}
	}
</script>

<style>
	
</style>