<template>
	<div style="min-width: 1000px;">
		<miniapp-main></miniapp-main>
	</div>
</template>

<script>
	import miniappMain from './components/miniapp/miniapp.vue';
	export default{
		components:{
			miniappMain
		},
		data(){
			return {
				
			}
		}
	}
</script>

<style>
	
	
</style>